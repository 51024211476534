import { useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useUser } from "../context/UserContext"
import { navigate } from "gatsby"
import { useToken } from "../context/UseToken"

const useAuthenticationCheck = () => {
  const { isAuthenticated, isLoading, user: auth0User } = useAuth0()
  const { setUser } = useUser()
  const { getToken } = useToken()

  const fetchUserData = async () => {
    try {
      const token = await getToken()
      const response = await fetch(`${process.env.GATSBY_API_URL}/v1/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      const userData = await response.json()
      console.log({ userData })
      setUser(userData)
    } catch (e) {
      console.error("Error fetching user data:", e)
    }
  }

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const queryString = window.location.search
      navigate("/" + queryString)
      return
    }

    if (isAuthenticated && auth0User?.sub) {
      fetchUserData()
    }
  }, [isAuthenticated, isLoading, auth0User?.sub, setUser])
}

export default useAuthenticationCheck
