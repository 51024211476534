import * as React from "react"
import { StyledButton } from "../Button"
import { useAuth0 } from "@auth0/auth0-react"

const Signup: React.FC = () => {
  const { loginWithRedirect, logout } = useAuth0()

  const handleSignup = async () => {
    await logout({ logoutParams: { localOnly: true, federated: true } })
    await loginWithRedirect({
      authorizationParams: { screen_hint: "signup", prompt: "login" },
    })
  }

  return (
    <StyledButton $primary onClick={() => handleSignup()}>
      Sign Up
    </StyledButton>
  )
}

export default Signup
