exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-monitor-tsx": () => import("./../../../src/pages/monitor.tsx" /* webpackChunkName: "component---src-pages-monitor-tsx" */),
  "component---src-pages-paymentcancel-tsx": () => import("./../../../src/pages/paymentcancel.tsx" /* webpackChunkName: "component---src-pages-paymentcancel-tsx" */),
  "component---src-pages-paymentsuccess-tsx": () => import("./../../../src/pages/paymentsuccess.tsx" /* webpackChunkName: "component---src-pages-paymentsuccess-tsx" */),
  "component---src-pages-rules-tsx": () => import("./../../../src/pages/rules.tsx" /* webpackChunkName: "component---src-pages-rules-tsx" */)
}

