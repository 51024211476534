import React, { ReactNode } from "react"
import SidebarMenu from "./SidebarMenu"
import styled from "styled-components"
import colors from "../styles/colors"
import { useAuth0 } from "@auth0/auth0-react"

interface IProps {
  children?: ReactNode
}

const PageLayout: React.FC<IProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth0()
  console.log({ user })
  if (!isAuthenticated) {
    return <IndexWrapper>{children}</IndexWrapper>
  }
  return (
    <Wrapper>
      <SidebarMenu />
      <MainContent>{children}</MainContent>
    </Wrapper>
  )
}

export default PageLayout

const IndexWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: row;
  background: ${colors.pageBackground};
  position: relative;
`

const MainContent = styled.div`
  width: 100%;
  padding-left: 48px;
  display: flex;
  flex-direction: row;
`
