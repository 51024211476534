import * as React from "react"
import styled from "styled-components"
import { useAuth0 } from "@auth0/auth0-react"
import logo from "../assets/codaco.png"
import colors from "../styles/colors"
// import Premium from "./header/premium"
import Login from "./header/login"
import Signup from "./header/signup"
import { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useUser, User } from "../context/UserContext" // adjust the import path as needed

import useAuthenticationCheck from "../hooks/useAuthenticationCheck"

const Header: React.FC = () => {
  useAuthenticationCheck()
  const { user } = useUser()
  const { isAuthenticated } = useAuth0()

  const [loggingOut, setLoggingOut] = useState(false)

  useEffect(() => {
    if (!isAuthenticated && loggingOut) {
      setLoggingOut(false)
    }
  }, [isAuthenticated, loggingOut])

  const renderNavigationLinks = (user: User | null) => {
    if (user) {
      return (
        <>
          {(user.role === "ORGANIZATION_ADMIN" ||
            user.role === "SUPER_ADMIN") && (
            <>
              <Tab to="/rules" activeClassName="active">
                Rules
              </Tab>

              <Tab to="/monitor" activeClassName="active">
                Monitor
              </Tab>
            </>
          )}
          {user.role === "SUPER_ADMIN" && (
            <Tab to="/admin" activeClassName="active">
              Admin
            </Tab>
          )}

          {/* <Premium /> */}
        </>
      )
    }
    return (
      <Tab to="/account" style={{ textDecoration: "none" }}>
        Loading...
      </Tab>
    )
  }
  return (
    <HeaderStyling>
      <Link to="/dashboard" style={{ textDecoration: "none" }}>
        <Img src={logo} alt="Codaco logo" />
      </Link>
      <Wrapper>
        <Nav>
          <Tab to="/dashboard" activeClassName="active">
            Dashboard
          </Tab>
          {renderNavigationLinks(user)}
        </Nav>
        <SidebarBottom>
          {!isAuthenticated && <Signup />}
          <Tab to="/account" style={{ textDecoration: "none" }}>
            My account
          </Tab>
          <Login />
        </SidebarBottom>
      </Wrapper>
    </HeaderStyling>
  )
}

const SidebarBottom = styled.div`
  display: flex;
  flex-direction: column;
`

const HeaderStyling = styled.header`
    width: 320px;
    background: white;
    padding: 40px 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
`

const Tab = styled(Link)`
  margin-right: 20px;
  color: gray;
  text-decoration: none;
  padding: 8px 16px;
  display: inline-block;

  &.active {
    border: 2px solid ${colors.green2};
    color: ${colors.green2};
    // background-color: ${colors.green3};
  }

  &:hover {
    color: ${colors.green2};
  }
`

const Nav = styled.div`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const Img = styled.img`
  max-height: 40px;
  margin-top: 25px;
`

export default Header
