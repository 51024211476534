import * as React from "react"
import "./layout.css"
import { ReactNode } from "react"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "gatsby"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import PageLayout from "./PageLayout"

interface IProps {
  children?: ReactNode
}

const Layout: React.FC<IProps> = ({ children }) => {
  const redirectCallback = (appState: any) => {
    navigate(appState?.returnTo || window.location.pathname)
  }
  const domain = process.env.GATSBY_AUTH0_DOMAIN || ""
  const clientId = process.env.GATSBY_AUTH0_CLIENT_ID || ""
  const audience = process.env.GATSBY_AUTH0_AUDIENCE || ""
  const redirectUri = process.env.GATSBY_AUTH0_REDIRECTURI || ""

  return (
    <>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: `${redirectUri}`,
          audience: audience,
        }}
        onRedirectCallback={redirectCallback}
      >
        <PageLayout>{children}</PageLayout>
      </Auth0Provider>
      <ToastContainer />
    </>
  )
}

export default Layout
