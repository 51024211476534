import * as React from "react"
import { StyledButton } from "../Button"
import { useAuth0 } from "@auth0/auth0-react"
import { useEffect, useState } from "react"

const Login: React.FC = () => {
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0()

  const [loggingOut, setLoggingOut] = useState(false)

  const handleLogin = async (isLoggingOut: boolean) => {
    const action = isLoggingOut ? "Logging out" : "Logging in"
    console.log(action)
    isLoggingOut
      ? logout({ logoutParams: { returnTo: window.location.origin } })
      : loginWithRedirect({
          authorizationParams: { screen_hint: "login", prompt: "login" },
        })
  }

  useEffect(() => {
    if (!isAuthenticated && loggingOut) {
      handleLogin(false)
      setLoggingOut(false)
    }
  }, [isAuthenticated, loggingOut])

  return (
    <StyledButton onClick={() => handleLogin(isAuthenticated)}>
      {isAuthenticated ? "Log out" : "Log In"}
    </StyledButton>
  )
}

export default Login
