import React from "react"
import Layout from "./src/components/layout"
import { UserProvider } from "./src/context/UserContext"

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
  return <UserProvider>{element}</UserProvider>
}
