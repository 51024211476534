import React, { createContext, useContext, useState, ReactNode } from "react"

export interface User {
  id: number
  email: string
  name: string
  isPremium: boolean
  isVerified: boolean
  auth0Id: string
  stripeId: string
  organizationId: number
  role: string
}

interface UserContextData {
  user: User | null
  setUser: React.Dispatch<React.SetStateAction<User | null>>
}

interface UserProviderProps {
  children: ReactNode
}

const UserContext = createContext<UserContextData | null>(null)

export const useUser = (): UserContextData => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error("useUser must be used within a UserProvider")
  }
  return context
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null)

  const value = {
    user,
    setUser,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
