import { Color } from "../models/colors"

/**
 * Colors
 */
export default {
  black: "#000000",
  black2: "#130E35",
  grey1: "#CCCFDB",
  grey2: "#99A0BC",
  grey3: "#F9F9F9",
  grey4: "#B3B8CB",
  grey5: "#24252a",
  grey6: "#6E758B",
  grey7: "#E5EEF5",
  grey8: "#F2F6FA",
  grey9: "#F7FAFC",
  neutralWhite: "#FFFFFF",
  purple: "#43297a",
  red: "#FF0000",
  dodgerBlue: "#1e90ff",
  lightBlue: "#E7EEF4",
  blue1: "#005AA0",
  blue2: "#005AA01A",
  purple1: "#AD52BB",
  purple2: "#AD52BB1A",
  green1: "#CCDEEC",
  green2: "#41C5CE",
  green3: "#1AA298",
  green4: "#1AA2981A",
  lightOrange: "#FD532D33",
  orange: "#FD532D",
  pageBackground: "#F8FAFC",
  shadow: "#130E350D",
  transparent: "transparent",
}

/**
 * Some components allow color customization. Since that's
 * optional, use this as a default fallback color in case
 * it's unset
 */
export const defaultColor: Color = "grey5"

export const defaultTextColor: Color = "grey5"
