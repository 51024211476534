import { useAuth0 } from "@auth0/auth0-react"

export const useToken = () => {
  const { getAccessTokenSilently } = useAuth0()

  const getToken = async () => {
    try {
      return await getAccessTokenSilently({
        authorizationParams: {
          audience: `${process.env.GATSBY_AUTH0_AUDIENCE}`,
          scope: "read:current_user",
        },
      })
    } catch (e) {
      console.error("Error fetching access token:", e)
      return null
    }
  }

  return {
    getToken,
  }
}
